import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import AuthPage from "./pages/AuthPage";
import ResetPassword from './pages/ResetPassword';


function App() {

  return (
    <>
      <Router >
        <Routes >
          <Route element={<AuthPage />} path='/' />
          <Route element={<ResetPassword />} path='/reset_password' />

        </Routes>
      </Router>
    </>
  );
}

export default App;
