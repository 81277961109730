import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
} from "@chakra-ui/react";

import { useState } from "react";

import { useLocation } from "react-router-dom";

import validator from "validator";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AuthPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [app, setApp] = useState(
    searchParams.get("app") !== null ? searchParams.get("app") : "dawuroboX"
  );

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [number, setNumber] = useState("");
  const [isBusy, setIsBusy] = useState(false);

  const appData = {
    dawurobo: {
      displayName: "Dawurobotel",
      desc: "Dawurobo is a free Ghanaian cross-platform for room buying, selling and booking.",
      returnURL: "http://share.dawurobo.com",
    },
    dawuroboX: {
      displayName: "Dawurobo X",
      desc: "Dawurobo X is a place where everyone has a voice. Our platform is aimed at encouraging people to interact with each other locally in meaningful ways. It doesn’t matter who you are or where you come from, what matters is what you have to say. This is a place where you’ll find lots of new stuff to love as you get to know your community. We aspire for our communities to be helpful & friendly so that everybody here can have a good time",
      returnURL: "https://dawurobo.page.link",
    },
    dawuroboSMS: {
      displayName: "Dawurobo SMS",
      desc: `Dawurobo SMS is one of the affiliate products of 
                                Thiside Prime Enterprise. Other affiliate products owned by the 
                                company are, Dawurobo, Dawurobo Food, Dawurobo Shop, Dawurobo Safe 
                                and Dawurobo X. It is our vision to make life easy and simple 
                                using technology.`,
      returnURL: "https://sms.dawurobo.com/auth",
    },
  };

  const toastData = {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const authenticate = async () => {
    if (!name) {
      return toast.error("Name is required", toastData);
    }
    if (!password) {
      return toast.error("Password is required", toastData);
    }
    if (!email) {
      return toast.error("Email is required", toastData);
    }
    if (!number) {
      return toast.error("Number is required", toastData);
    }

    if (!validator.isEmail(email)) {
      return toast.error("Please enter a valid email", toastData);
    }

    setIsBusy(true);

    const userAgent = navigator.userAgent;

    let device = "";

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      device = "iOS";
    } else if (/Android/.test(userAgent)) {
      device = "Android";
    } else if (/Windows/.test(userAgent)) {
      device = "Windows";
    } else if (/Linux/.test(userAgent)) {
      device = "Linux";
    } else if (/Mac/.test(userAgent)) {
      device = "macOS";
    } else {
      device = "Other";
    }

    const res = await fetch(
      "https://gateway.api.dawurobo.com/v1/dawurobo_users/v3/user/new",
      {
        method: "POST",
        headers: {
          "Content-Type": "Application/JSON",
        },
        body: JSON.stringify({
          user: {
            name: name,
            number: number,
            email: email.toLocaleLowerCase(),
            app: app,
            password: password,
          },
          extra_data: { device },
        }),
      }
    ).catch((err) => {
      setIsBusy(false);
    });

    const resJson = await res.json();

    if (resJson.status) {
      if (app === "dawuroboX") {
        if (device === "android") {
          window.location.replace(
            "https://play.google.com/store/apps/details?id=com.dawurobo.dawurobox"
          );
        } else {
          window.location.replace(
            "https://apps.apple.com/gh/app/dawurobo-x/id1577796580"
          );
        }
      }
      window.location.replace(appData[app].returnURL);
    } else {
      setIsBusy(false);

      return toast.error(resJson.message, toastData);
    }
  };

  return (
    <Stack minH={"100vh "} direction={{ base: "column", md: "row" }}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={4} w={"full"} maxW={"md"}>
          <Heading fontSize={"2xl"}>One Dawurobo Account for all!</Heading>
          <FormControl id="name">
            <FormLabel>Name</FormLabel>
            <Input
              onChange={(e) => setName(e.target.value)}
              value={name}
              type="name"
            />
          </FormControl>
          <FormControl id="email">
            <FormLabel>Email address</FormLabel>
            <Input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="email"
            />
          </FormControl>
          <FormControl id="number">
            <FormLabel>Number</FormLabel>
            <Input
              onChange={(e) => setNumber(e.target.value)}
              value={number}
              type="number"
            />
          </FormControl>
          <FormControl id="password">
            <FormLabel>Password</FormLabel>
            <Input
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type="password"
            />
          </FormControl>
          <FormControl id="app">
            <FormLabel>App</FormLabel>
            <RadioGroup onChange={(e) => setApp(e)} defaultValue={app}>
              {Object.keys(appData).map((key) => (
                <Tooltip key={key} label={appData[key].desc} placement="top">
                  <Box>
                    <Radio marginX={10} value={key}>
                      {appData[key].displayName}
                    </Radio>
                  </Box>
                </Tooltip>
              ))}
            </RadioGroup>
          </FormControl>

          <Button
            isLoading={isBusy}
            colorScheme={"blue"}
            variant={"solid"}
            onClick={authenticate}
          >
            Join {appData[app].displayName}
          </Button>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image alt={`${app} image`} objectFit={"fit"} src={"/logo.png"} />
      </Flex>
    </Stack>
  );
}
